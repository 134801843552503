import React from "react";
import { Navigate, Route, Routes } from "react-router";
import SellNFT from "../../components/actions/sellNFT/sellNFT";
import Profile from "../profile/profile";

const Account = () => {
  return (
    <>
      <Routes>
        <Route path="" element={<Profile />} />
        <Route path="/:contract/:id" element={<SellNFT />} />
        <Route path={"*"} element={<Navigate replace to="" />} />
      </Routes>
    </>
  );
};

export default Account;
