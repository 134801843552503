import apiRequest from "../../helpers/connections.js";

export const myNFTs = async (add) => {
  try {
    const response = await apiRequest(
      "nfts/address/" + add,
      undefined,
      undefined,
      undefined
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
