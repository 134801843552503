import React from "react";
import { Sparklines, SparklinesCurve } from "react-sparklines";

import styles from "./chart.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { orders } from "../../../actions/helper";
import { useDispatch } from "react-redux";

const Chart = ({data}) => {
  const _price = data.map((item)=> item.price)
  const price = _price.length > 10 ? _price.slice(_price.length - 11, _price.length - 1): _price
  const change = (_price[_price.length-1] / _price.length - 2) * 100 - 100
const dispatch= useDispatch()
  const [order, setorder] = useState([])

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const res = await orders();

      const result = res.data.map((item)=>item.price)
      setorder(result);
    })();
    return () => abortController.abort();
  }, [dispatch]);

  return (
    <div className={styles.chart}>
      <div>
        <div>
          <p>Performance</p>
        </div>
        <div>
          <span>Floor price</span>
          <div className={styles.price}>
            <p>{Math.min(...order)}</p>
            <img src={require("../../../../assets/eth.png")} alt="eth" />

          </div>
          <div className={styles.change}>
            <span className={change > 0 ? styles.green : styles.red}>{change > 0 && "+"}{change.toFixed(2)}%</span>
          </div>
        </div>
      </div>
      <div className={styles.spark}>
        <Sparklines
          data={price}
          min={0}
          preserveAspectRatio="xMinYMin "
          height={150}
        >
          <SparklinesCurve
            style={{ strokeWidth: 2, stroke: "#E30E64", fill: "#A90B98" }}
          />
        </Sparklines>
      </div>
    </div>
  );
};

export default Chart;
