import React from "react";

import styles from "./nft.module.css";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
import Button from "../button/button";

const Nft = () => {
  return (
    <SectionWidth className={styles.nfts}>
      <img src={require("../../../assets/nftssection.png")} alt="nftSection" />
      <div>
        <h2>Get access to NFTS</h2>
        <p>
          Raise capital without draining your resources. Buy, sell, and trade
          tokenized assets on a seamless, fully secured integrated platform.
        </p>
        <Button path="/mint" title="Check out" />
      </div>
    </SectionWidth>
  );
};

export default Nft;
