import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalOwned: 0,
  totalStaked: 0,
  totalSupply: 0,
  totalHolders: 0,
};

const heroStatsSlice = createSlice({
  name: "heroStats",
  initialState,
  reducers: {
    setAccount(state, action) {
      state.totalOwned = action.payload.totalOwned;
      state.totalStaked = action.payload.totalStaked;
    },
    setCollection(state, action) {
      state.totalSupply = action.payload.totalSupply;
      state.totalHolders = action.payload.totalHolders;
    },
  },
});

export const heroStatsActions = heroStatsSlice.actions;

export default heroStatsSlice;
