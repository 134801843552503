import React, { useEffect } from "react";

import styles from "./activity.module.css";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
import Chart from "./chart/chart";
import History from "./history/history";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { getMeta, transactions } from "../../actions/helper";

const Activity = () => {
  const [data, setdata] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const res = await transactions();

      const result = await new Promise((resolve, reject) => {
        const arr = [];
        res.data.map(async (item, index) => {
          try {
            const meta = await getMeta(dispatch, item.token_id);
            delete meta.date;
            arr.push({ ...item, ...meta });

            if (index === res.data.length - 1) {
              resolve(arr);
            }
          } catch (error) {
            console.log(error);
          }
        });
      });

      setdata(result);
    })();
    return () => abortController.abort();
  }, [dispatch]);
  return (
    <SectionWidth className={styles.activity}>
      <Chart data={data} />
      <History data={data} />
    </SectionWidth>
  );
};

export default Activity;
