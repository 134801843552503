import React, { useRef, useState } from "react";

import styles from "./carousel.module.css";
import Card from "./card/card";
const Carousel = () => {
  

  const items = [
    {
      color: "#352673",
      img: require("../../../../assets/art/7 CONE HEAD.png"),
      name: "Cone'Head Chuck",
      floor: 4.98,
      volume: 38.4,
    },
    {
      color: "#424B5C",
      img: require("../../../../assets/art/19 CLOWN SHOW.png"),
      name: "Jazmynd Clown",
      floor: 4.98,
      volume: 38.4,
    },
  ];
  const ref = useRef(null);

  const [state, setState] = useState("prev");

  const prev = () => {
    setState("prev");
    ref.current.scroll({
      left: -500,
      behavior: "smooth",
    });
  };

  const next = () => {
    setState("next");

    ref.current.scroll({
      left: 500,
      behavior: "smooth",
    });
  };
  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.flex}>
        {items.map((item, index) => {
          return <Card key={index} data={item} />;
        })}
      </div>
      <div className={styles.control}>
        <div
          className={state === "prev" ? styles.active : undefined}
          onClick={prev}
        ></div>
        <div
          className={state === "next" ? styles.active : undefined}
          onClick={next}
        ></div>
      </div>
    </div>
  );
};

export default Carousel;
