import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
const PrivateRoutes = () => {
  const {
    connectionState: { connected },
  } = useSelector((state) => state.connection);

  return connected ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
