import React from "react";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
import styles from "./available.module.css";
import Itemsgrid from "./itemsgrid/itemsgrid";

const Available = () => {
  return (
    <SectionWidth className={styles.items}>
      <Itemsgrid />
    </SectionWidth>
  );
};

export default Available;
