import { createSlice } from "@reduxjs/toolkit";
import { setLocalStorage, getFromLocalStorage } from "../../helpers/utils";

const initialState = getFromLocalStorage("connection") || {
  connectionState: {
    connected: false,
    address: "",
  },
  balance: 0,
};

const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    setConnection(state, action) {
      state.connectionState = action.payload;
      localStorage.removeItem("connection");
      setLocalStorage("connection", state);
      setLocalStorage("expiry", new Date().getTime() + 3600000);
    },
    setBalance(state, action) {
      state.balance = action.payload;
    },
  },
});

export const connectionActions = connectionSlice.actions;

export default connectionSlice;
