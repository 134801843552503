import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router";

import Hero from "../../components/collection/hero/hero";
import Items from "../../components/collection/items/items";
import Activity from "../../components/collection/activity/activity";
import BuyNFT from "../../components/actions/BuyNFT/BuyNFT";

const Pangs = () => {
  const [activity, setActivity] = useState(false);

  return (
    <>
      <Hero activity={activity} setActivity={setActivity} />
      {activity ? <Activity /> : <Items />}
    </>
  );
};

const Collection = () => {
  return (
    <Routes>
      <Route path="" element={<Pangs />} />
      <Route path="listing/:id" element={<BuyNFT />} />
      <Route path={"*"} element={<Navigate replace to="" />} />
    </Routes>
  );
};

export default Collection;
