import React from "react";

import styles from "./hero.module.css";
import { SectionWidth } from "../../../sectionWidth/sectionWidth";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { handleUnStake } from "../../helper";

const Hero = ({ data }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();

  const unStakeNFT = async () => {
    await handleUnStake(id, dispatch, navigate);
  };

  return (
    <SectionWidth className={styles.hero}>
      <div className={styles.banner}>
        <img src={data.image} alt={data.name} />
        <p>{data.description}</p>
      </div>
      <div className={styles.details}>
        <div className={styles.name}>
          <img src={data.image} alt={data.name} />
          <h4>{data.name}</h4>
          <img
            src={require("../../../../assets/verified.png")}
            alt="verified"
          />
          <img src={require("../../../../assets/share.png")} alt="share" />
        </div>
        <h3>
          {data.name} #{data.id}
        </h3>

        <div className={styles.price}>
          <div>
            <p>Price</p>
            <div>
              <p>{4.98}</p>
              <img src={require("../../../../assets/eth.png")} alt="eth" />
            </div>
            <span>$45</span>
          </div>
          <div>
            <p>Floor price</p>
            <div>
              <p>{2.98}</p>
              <img src={require("../../../../assets/eth.png")} alt="eth" />
            </div>
            <span>$35</span>
          </div>
        </div>
        <button onClick={unStakeNFT}>Unstake</button>
      </div>
    </SectionWidth>
  );
};

export default Hero;
