import React, { useEffect, useState } from "react";

import NftCard from "../../../nftCard/nftCard";
import styles from "./itemsgrid.module.css";
import { myNFTs } from "../../../../pages/profile/profilehelpers";
import { useDispatch, useSelector } from "react-redux";
import { notificationActions } from "../../../../store/notification/notification";

const Itemsgrid = () => {
  const [priceBar, setPriceBar] = useState("Price - Low to High");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [search, setsearch] = useState("");
  const [filtered, setfiltered] = useState([]);
  const dispatch = useDispatch();
  const {
    connectionState: { address },
  } = useSelector((state) => state.connection);


  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      dispatch(notificationActions.setNotify(true));

      const res = await myNFTs(address);
      setData(res);
      dispatch(notificationActions.setNotify(false));
    })();
    return () => abortController.abort();
  }, [address, dispatch]);
  useEffect(() => {
    if (search.length > 0) {
      const filtered = data.filter((item) => {
        const meta = item.hasOwnProperty("metadata") ? true : false;
        const name = meta ? item.metadata.name : item.name;
        return name.toLowerCase().includes(search.toLowerCase());
      });

      setfiltered(filtered);
    } else {
      setfiltered(data);
    }
  }, [search, data]);
  useEffect(() => {
    setOpen(false);
  }, [priceBar]);
  return (
    <div className={styles.items}>
      <div>
        <div className={styles.searchBar}>
          <img src={require("../../../../assets/Search.png")} alt="search" />
          <input
            type="text"
            name="search"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div className={styles.priceBar}>
          <div onClick={() => setOpen((prevState) => !prevState)}>
            <p>{priceBar}</p>
            <img
              src={require("../../../../assets/ArrowDown.png")}
              alt="ArrowDown"
            />
          </div>
          <div
            className={`${styles.dropDown} ${open ? styles.show : undefined}`}
          >
            <li
              onClick={() => setPriceBar("Price - Low to High")}
              className={
                priceBar.includes("Price - Low to High")
                  ? styles.none
                  : undefined
              }
            >
              Price - Low to High
            </li>
            <li
              onClick={() => setPriceBar("Price - High to Low")}
              className={
                priceBar.includes("Price - High to Low")
                  ? styles.none
                  : undefined
              }
            >
              Price - High to Low
            </li>
          </div>
        </div>
      </div>
      <div className={styles.mapCards}>
        {filtered.length > 0 ? (
          filtered.map((item, index) => {
            return (
              <NftCard
                key={index}
                data={item}
                to={`0xces51fdg516d5g6d5sf5d161d5f6/${item.tokenId}`}
              />
            );
          })
        ) : (
          <p className={styles.empty}>There are no NFTs in this section</p>
        )}
      </div>
    </div>
  );
};

export default Itemsgrid;
