import React from "react";

import styles from "./card.module.css";
const Card = ({ data }) => {
  return (
    <div className={styles.card} style={{ background: data.color }}>
      <img src={data.img} alt="name" />
      <div className={styles.name}>
        <p>{data.name}</p>
        <img src={require("../../../assets/verified.png")} alt="verified" />
      </div>
      <div className={styles.transCard}>
        <div>
          <span>Floor</span>
          <div>
            <p>{data.floor}</p>
            <img src={require("../../../assets/eth.png")} alt="ethereum" />
          </div>
        </div>
        <div>
          <span>Volume</span>
          <div>
            <p>{data.volume}k</p>
            <img src={require("../../../assets/eth.png")} alt="ethereum" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
