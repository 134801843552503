import React, { useEffect, useState } from "react";

import styles from "./hero.module.css";
import { SectionWidth } from "../../../sectionWidth/sectionWidth";
import { useDispatch, useSelector } from "react-redux";
import { orderByTokenId, purchase, unListToken } from "../../helper";
import { useNavigate } from "react-router";

const Hero = ({data, history}) => {
  const [order, setorder] = useState()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    connectionState: { address },
  } = useSelector((state) => state.connection);
  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const _order = await orderByTokenId(data?.id)
      setorder(_order.data);
    })();
    return () => abortController.abort();
  }, [data]);


  const handleUnlist = async ()=> {

    await unListToken(dispatch, order.item_id, navigate)
  }
  const handleBuy = async ()=>{
    await purchase(dispatch, order.item_id,history[0]?.price, navigate)

  }
  

  return (
    <SectionWidth className={styles.hero}>
      <div className={styles.banner}>
        <img src={data.image} alt={data.name} />
        <p>{data.description}</p>
      </div>
      <div className={styles.details}>
        <div className={styles.name}>
          <img src={data.image} alt={data.name} />
          <h4>{data.name}</h4>
          <img
            src={require("../../../../assets/verified.png")}
            alt="verified"
          />
          <img src={require("../../../../assets/share.png")} alt="share" />
        </div>
        <h3>
          {data.name} #{data.id}
        </h3>
        <span>Owner</span>
        <div className={styles.owner}>
          <img src={require("../../../../assets/avatar.png")} alt="avatar" />
          <p>
            {history[0]?.address?.slice(0, 7)}...
            {history[0]?.address?.slice(history[0]?.address.length - 7, history[0]?.address?.length)}
          </p>
        </div>
        <div className={styles.price}>
          <div>
            <p>Price</p>
            <div>
              <p>{history[0]?.price}</p>
              <img src={require("../../../../assets/eth.png")} alt="eth" />
            </div>
            <span>$45</span>
          </div>
          <div>
            <p>Floor price</p>
            <div>
              <p>{data.floor}</p>
              <img src={require("../../../../assets/eth.png")} alt="eth" />
            </div>
            <span>$35</span>
          </div>
        </div>
        <button onClick={address === history[0]?.address ? handleUnlist : handleBuy}>
          {address === history[0]?.address ? <span>Unlist NFT</span> :<>
          <span>Buy for {history[0]?.price} </span>
          <img src={require("../../../../assets/eth.png")} alt="eth" />
          </>  }
          
        </button>
      </div>
    </SectionWidth>
  );
};

export default Hero;
