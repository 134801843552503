import React, { useState } from "react";
import Activity from "../../components/profile/activity/activity";
import Items from "../../components/profile/items/items";
import Hero from "../../components/profile/hero/hero";

const Profile = () => {
  const [activity, setActivity] = useState(false);

  return (
    <>
      <Hero activity={activity} setActivity={setActivity} />
      {activity ? <Activity /> : <Items />}
    </>
  );
};

export default Profile;
