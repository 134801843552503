import React, { useEffect, useState } from "react";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
import styles from "./items.module.css";
import Filters from "./filters/filters";
import Itemsgrid from "./itemsgrid/itemsgrid";
import { notificationActions } from "../../../store/notification/notification";
import { myNFTs } from "../../../pages/profile/profilehelpers";
import { useDispatch, useSelector } from "react-redux";
import { getMeta, getStakedIds, orderByAddress } from "../../actions/helper";
import { heroStatsActions } from "../../../store/herostats/herostats";

const Items = () => {
  const [availdata, setavaildata] = useState([]);
  const [listeddata, setlisteddata] = useState([]);
  const [filter, setfilter] = useState({ min: undefined, max: undefined });
  const [range, setrange] = useState([]);
  const dispatch = useDispatch();

  const [listing, setlisting] = useState(false)

  const {
    connectionState: { address },
  } = useSelector((state) => state.connection);

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      dispatch(notificationActions.setNotify(true));

      const res = await myNFTs(address);
      const staked = await getStakedIds(dispatch, address);
      dispatch(
        heroStatsActions.setAccount({
          totalOwned: res.length,
          totalStaked: staked.length,
        })
      );

      setavaildata(res);
      dispatch(notificationActions.setNotify(false));
    })();
    return () => abortController.abort();
  }, [address, dispatch, listing]);

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const res = await orderByAddress(address);

      const result = await new Promise((resolve, reject) => {
        const arr = [];
        res.data.map(async (item, index) => {
          try {
            const meta = await getMeta(dispatch, item.token_id);
            arr.push({ ...item, metadata : {...meta} });

            if (index === res.data.length - 1) {
              resolve(arr);
            }
          } catch (error) {
            console.log(error);
          }
        });
      });
      setlisteddata(result);
    })();
    return () => abortController.abort();
  }, [dispatch, address, listing]);

  useEffect(() => {
    let _filter;
    const data = listing ? listeddata : availdata
    if (filter.min && filter.max) {
      _filter = data.filter((item) => {
        return item.price >= filter.min && item.price <= filter.max;
      });
    } else if (filter.min && !filter.max) {
      _filter = data.filter((item) => {
        return item.price >= filter.min;
      });
    } else if (!filter.min && filter.max) {
      _filter = data.filter((item) => {
        return item.price <= filter.max;
      });
    } else {
      _filter = data;
    }

    setrange(_filter);
  }, [listing, availdata, listeddata, filter]);
  return (
    <SectionWidth className={styles.items}>
      <Filters setfilter={setfilter} setlisting={setlisting} listing={listing}/>
      <Itemsgrid listing={listing} data={range} />
    </SectionWidth>
  );
};

export default Items;
