import React from "react";

import styles from "./hero.module.css";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
import Text from "./text/text";
import Carousel from "./carousel/carousel";
const Hero = () => {
  return (
    <SectionWidth className={styles.hero}>
      <Text />
      <Carousel />
    </SectionWidth>
  );
};

export default Hero;
