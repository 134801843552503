import React from "react";
import Hero from "../../components/homepage/hero/hero";
import Nft from "../../components/homepage/nft/nft";
import Casino from "../../components/homepage/casino/casino";

const Homepage = () => {
  return (
    <>
      <Hero />
      <Nft />
      <Casino />
    </>
  );
};

export default Homepage;
