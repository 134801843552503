import React from "react";

import styles from "./popup.module.css";
import { useNavigate } from "react-router";
const PopUp = ({ data, setshowPop }) => {
  const navigate = useNavigate()
  return (
    <div className={styles.card}>
      <img src={data.image} alt="name" />
      <div className={styles.name}>
        <p>{data.name}</p>
        <img src={require("../../../assets/verified.png")} alt="verified" />
      </div>
      <div className={styles.transCard}>
        <p>You have just minted this NFT</p>
        <div>
        <button onClick={()=> { setshowPop(false); navigate("/catalogue")}}>Read More</button>
        <button onClick={()=> setshowPop(false)}>Close</button>
        </div>
       
      </div>
    </div>
  );
};

export default PopUp;
