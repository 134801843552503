import React from "react";
import ReactTimeAgo from "react-time-ago";
import styles from "./historyCard.module.css";

const HistoryCard = ({ data }) => {
  return (
    <div className={styles.card}>
      <img
        src={require(`../../../../../assets/${
          data.type === "sale" ? "buy.png" : "list.png"
        }`)}
        alt="type"
      />
      <img src={data.image} alt="collection" />
      <div className={styles.details}>
        <p>
          {data.name} #{data.token_id}
        </p>
        <span>
          {data.type === "sale" ? "bought" : data.type === "unlist" ? "unlisted": "listed"} by{" "}
          <strong>
            {data.address.slice(0, 7)}...
            {data.address.slice(data.address.length - 7, data.address.length)}
          </strong>{" "}
          for <strong>{data.price}</strong>
          <img src={require("../../../../../assets/eth.png")} alt="eth" />
        </span>
      </div>
      <p>
        {<ReactTimeAgo date={new Date(data.date).getTime()} locale="en-US" />}
      </p>
    </div>
  );
};

export default HistoryCard;
