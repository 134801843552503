import React, { useState } from "react";

import { SectionWidth } from "../../components/sectionWidth/sectionWidth";
import styles from "./mint.module.css";
import Card from "../../components/mint/card/card";
import MintBox from "../../components/mint/mintBox/mintBox";
import PopUp from "../../components/mint/popup/popup";

const Mint = () => {
  const [showPop, setshowPop] = useState(false)
  const [newNFT, setnewNFT] = useState({})
  const data = {
    color: "#424B5C",
    img: require("../../assets/diapernft.png"),
    name: "Diaper Lord",
    floor: 4.98,
    volume: 38.4,
  };
  return (
    <>
      <SectionWidth className={styles.mint}>
        <img className={styles.ace} src={require("../../assets/ace.png")} alt="ace" />
        <img className={styles.threebrothers} src={require("../../assets/threebrothers.png")} alt="threebrothers" />
        <Card data={data} />
        <MintBox setnewNFT={setnewNFT} setshowPop={setshowPop} />
        {showPop && <PopUp data={newNFT} setshowPop={setshowPop} />}
      </SectionWidth>
    </>
  );
};

export default Mint;
