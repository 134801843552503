import React, { useState } from "react";

import styles from "./hero.module.css";
import { SectionWidth } from "../../../sectionWidth/sectionWidth";
import { checkSaleApproved, handleSaleApproval, listToken } from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";

const Hero = ({ data }) => {
  const [approved, setApproved] = useState(false);

  const [amountValue, setamountValue] = useState(4.98);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    connectionState: { address },
  } = useSelector((state) => state.connection);
  const listNFT = async () => {
    await listToken(dispatch, data.id, amountValue, navigate);
  };

  const approveNFT = async () => {
    await handleSaleApproval(dispatch, setApproved);
  };

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      
      checkSaleApproved(address, setApproved, dispatch);
    })();
    return () => abortController.abort();
  }, [address, dispatch, data]);

  
  return (
    <SectionWidth className={styles.hero}>
      <div className={styles.banner}>
        <img src={data.image} alt={data.name} />
        <p>{data.description}</p>
      </div>
      <div className={styles.details}>
        <div className={styles.name}>
          <img src={data.image} alt={data.name} />
          <h4>{data.name}</h4>
          <img
            src={require("../../../../assets/verified.png")}
            alt="verified"
          />
          <img src={require("../../../../assets/share.png")} alt="share" />
        </div>
        <h3>
          {data.name} #{data.id}
        </h3>

        <div className={styles.price}>
          <div>
            <p>Price</p>
            <div>
              <p>{amountValue}</p>
              <img src={require("../../../../assets/eth.png")} alt="eth" />
            </div>
            <span>$45</span>
          </div>
          <div>
            <p>Floor price</p>
            <div>
              <p>{2.98}</p>
              <img src={require("../../../../assets/eth.png")} alt="eth" />
            </div>
            <span>$35</span>
          </div>
        </div>
        <div className={styles.form}>
          <div>
            <p>Listing Price</p>
            <span>ETHEREUM</span>
          </div>
          <input
            onChange={(e) => setamountValue(e.target.value)}
            type="tel"
            name="amount"
            value={amountValue}
          />
        </div>
        <button onClick={approved ? listNFT : approveNFT  }>
          {approved ? <>
            <span>List for {amountValue} </span>
            <img src={require("../../../../assets/eth.png")} alt="eth" />
          </> : <span> Approve </span>}
          
        </button>
      </div>
    </SectionWidth>
  );
};

export default Hero;
