import React from "react";

import styles from "./nftCard.module.css";
import { useNavigate } from "react-router";

const NftCard = ({ data, to }) => {
  const meta = data.hasOwnProperty("metadata") ? true : false;
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(to)}
      className={styles.container}
    >
      {meta ? (
        <img src={data.metadata.image} alt={data.name} />
      ) : (
        <img src={require("../../assets/nopreview.png")} alt="no preview" />
      )}

      <div className={styles.details}>
        <div>
          <div>
            <p>{meta ? data.metadata.name : data.name}</p>
            <img src={require("../../assets/verified.png")} alt="verified" />
          </div>
          <span>
            {meta ? data.metadata.name : data.name} #{data.tokenId}
          </span>
        </div>

        <div className={styles.price}>
          <span>Price</span>
          <div>
            <p>{data.price ? data.price : "--"}</p>
            <img src={require("../../assets/eth.png")} alt="eth" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftCard;
