import React from "react";
import styles from "./cards.module.css";

const Cards = ({ data }) => {
  return (
    <div className={styles.card}>
      <div style={{ backgroundColor: data.color }}>
        <img src={data.img} alt={data.name} />
      </div>
      <div>
        <p>{data.name}</p>
        <span className={styles.top}>  {data.description}</span>
        <span>{data.story}</span>
      </div>
    </div>
  );
};

export default Cards;
