
import styles from "./filters.module.css";

const Filters = ({ setfilter, setlisting, listing }) => {
  const handleRange = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const min = parseInt(form.get("min"));
    const max = parseInt(form.get("max"));
    setfilter({ min: min ? min : undefined, max: max ? max : undefined });
  };
  return (
    <div className={styles.filter}>
      <div onClick={() => setfilter({ min: undefined, max: undefined })}>
        <p>Clear</p>
        <img src={require("../../../../assets/Filter.png")} alt="filter" />
      </div>

      <div className={styles.listed}>
        <p>Listed</p>
        <div
          onClick={() => setlisting((prevState) => !prevState)}
          className={`${styles.switch} ${listing ? styles.list : undefined}`}
        >
          <div></div>
        </div>
      </div>
      <form onSubmit={handleRange} className={styles.pricefilter}>
        <p>Price Range (ETHEREUM)</p>
        <div className={styles.inputContainer}>
          <input type="number" min="0" name="min" placeholder="MIN" />
          <input type="number" min="0" name="max" placeholder="MAX" />
        </div>
        <button>Apply</button>
      </form>
    </div>
  );
};

export default Filters;
