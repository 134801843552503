import React from "react";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
import styles from "./staked.module.css";
import Itemsgrid from "./itemsgrid/itemsgrid";

const Staked = () => {
  return (
    <SectionWidth className={styles.items}>
      <Itemsgrid />
    </SectionWidth>
  );
};

export default Staked;
