import React from "react";

import styles from "./tokenDetails.module.css";
import { useEffect } from "react";
import { useState } from "react";

const TokenDetails = ({ data }) => {
  const [details, setdetails] = useState([]);
  const [traits, settraits] = useState([]);

  useEffect(() => {
    const details = { ...data };
    const traits = Object.keys(data).length > 0 ? [...data.attributes] : [];
    delete details.image;
    delete details.compiler;
    delete details.attributes;
    delete details.date;
    delete details.id;
    setdetails(details);
    settraits(traits);
  }, [data]);

  return (
    <div>
      <div className={styles.token}>
        <p>Token Details</p>
        {Object.keys(details).length > 0 &&
          Object.keys(details).map(function (key, index) {
            return (
              <div key={index}>
                <span>{key}</span>
                <p>{details[key]}</p>
              </div>
            );
          })}
        <div>
          <span>Token ID</span>
          <p>{data.id}</p>
        </div>
      </div>
      <div className={styles.token}>
        <p>Metadata</p>
        {traits.length > 0 &&
          traits.map(function (item, index) {
            return (
              <div key={index}>
                <span>{item.trait_type}</span>
                <p>{item.value}</p>
              </div>
            );
          })}
        <div>
          <span>Blockchain</span>
          <p>Ethereum</p>
        </div>
        <div>
          <span>Token Standard</span>
          <p>ERC720</p>
        </div>
      </div>
    </div>
  );
};

export default TokenDetails;
