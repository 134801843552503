import React from "react";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
import styles from "./items.module.css";
import Filters from "./filters/filters";
import Itemsgrid from "./itemsgrid/itemsgrid";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { getMeta, orders } from "../../actions/helper";

const Items = () => {
  const [data, setdata] = useState([]);
  const [filter, setfilter] = useState({ min: undefined, max: undefined });
  const [range, setrange] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const res = await orders();

      const result = await new Promise((resolve, reject) => {
        const arr = [];
        res.data.map(async (item, index) => {
          try {
            const meta = await getMeta(dispatch, item.token_id);
            arr.push({ ...item, ...meta });

            if (index === res.data.length - 1) {
              resolve(arr);
            }
          } catch (error) {
            console.log(error);
          }
        });
      });
      setdata(result);
    })();
    return () => abortController.abort();
  }, [dispatch]);

  useEffect(() => {
    let _filter;
    if (filter.min && filter.max) {
      _filter = data.filter((item) => {
        return item.price >= filter.min && item.price <= filter.max;
      });
    } else if (filter.min && !filter.max) {
      _filter = data.filter((item) => {
        return item.price >= filter.min;
      });
    } else if (!filter.min && filter.max) {
      _filter = data.filter((item) => {
        return item.price <= filter.max;
      });
    } else {
      _filter = data;
    }

    setrange(_filter);
  }, [data, filter]);

  return (
    <SectionWidth className={styles.items}>
      <Filters setfilter={setfilter} />
      <Itemsgrid data={range} />
    </SectionWidth>
  );
};

export default Items;
