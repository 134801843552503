import React from "react";

import Hero from "./hero/hero";
import styles from "./unStakeNFT.module.css";
import TokenDetails from "../tokenDetails/tokenDetails";
import History from "../../collection/activity/history/history";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { getMeta, getTokenId, tokenTransactions } from "../helper";
const UnStakeNFT = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;

  const [data, setdata] = useState([]);

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const tokenId = await getTokenId(dispatch, id);
      const meta = await getMeta(dispatch, tokenId);

      setdata(meta);
    })();
    return () => abortController.abort();
  }, [dispatch, id]);

  
  const [history, sethistory] = useState([]);

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const res = await tokenTransactions(id);

      const result = await new Promise((resolve, reject) => {
        const arr = [];
        res.data.map(async (item, index) => {
          try {
            const meta = await getMeta(dispatch, item.token_id);
            delete meta.date;
            arr.push({ ...item, ...meta });

            if (index === res.data.length - 1) {
              resolve(arr);
            }
          } catch (error) {
            console.log(error);
          }
        });
      });

      sethistory(result);
    })();
    return () => abortController.abort();
  }, [dispatch, id]);
  return (
    <>
      <Hero data={data} />
      <div className={styles.lowerContainer}>
        <TokenDetails data={data} />
        <History data={history}/>
      </div>
    </>
  );
};

export default UnStakeNFT;
