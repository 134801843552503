import React, { useEffect, useState } from "react";

import styles from "./history.module.css";
import HistoryCard from "./historyCard/historyCard";

const History = ({ data  }) => {
  const [filter, setFilter] = useState("All");
  const [filtered, setfiltered] = useState(data);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (filter === "All") {
      setfiltered(data);
    } else if (filter === "Sales") {
      setfiltered(data.filter((item) => item.type === "sale"));
    } else if (filter === "Listing") {
      setfiltered(data.filter((item) => item.type === "list"));
    }
  }, [filter, data]);

  useEffect(() => {
    setOpen(false);
  }, [filter]);

  return (
    <div>
      <div className={styles.header}>
        <button>
          <img src={require("../../../../assets/refresh.png")} alt="refresh" />
        </button>
        <div className={styles.filter}>
          <div onClick={() => setOpen((prevState) => !prevState)}>
            <p> {filter} </p>{" "}
            <img
              src={require("../../../../assets/ArrowDown.png")}
              alt="ArrowDown"
            />
          </div>{" "}
          <div
            className={`${styles.dropDown} ${open ? styles.show : undefined}`}
          >
            <li
              onClick={() => setFilter("All")}
              className={filter.includes("All") ? styles.none : undefined}
            >
              All{" "}
            </li>{" "}
            <li
              onClick={() => setFilter("Listing")}
              className={filter.includes("Listing") ? styles.none : undefined}
            >
              Listing{" "}
            </li>{" "}
            <li
              onClick={() => setFilter("Sales")}
              className={filter.includes("Sales") ? styles.none : undefined}
            >
              Sales{" "}
            </li>{" "}
          </div>{" "}
        </div>{" "}
      </div>
      {filtered.length > 0 ? filtered.map((item, index) => {
        return <HistoryCard key={index} data={item} />;
      }): <p className={styles.empty}>There are no transactions here</p>}
    </div>
  );
};

export default History;
