import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router";

import StakeNFT from "../../components/actions/stakeNFT/stakeNFT";
import UnStakeNFT from "../../components/actions/unStakeNFT/unStakeNFT";
import Hero from "../../components/stake/hero/hero";
import Available from "../../components/stake/available/available";
import Staked from "../../components/stake/staked/staked";

const StakeDash = () => {
  const [available, setAvailable] = useState(true);

  return (
    <>
      <Hero available={available} setAvailable={setAvailable} />
      {available ? <Available /> : <Staked />}
    </>
  );
};

const Stake = () => {
  return (
    <>
      <Routes>
        <Route path="" element={<StakeDash />} />
        <Route path="/:contract/:id" element={<StakeNFT />} />
        <Route path="staked/:id" element={<UnStakeNFT />} />
        <Route path={"*"} element={<Navigate replace to="" />} />
      </Routes>
    </>
  );
};

export default Stake;
