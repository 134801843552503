import React, { useEffect, useState } from "react";

import styles from "./card.module.css";
import { collectionStats } from "../../../../actions/helper";
const Card = ({ data }) => {
  const [info, setinfo] = useState(undefined);

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      const res = await collectionStats();
      setinfo(res.data);  
    })();
    return () => abortController.abort();
  }, []);
  return (
    <div className={styles.card} style={{ background: data.color }}>
      <img src={data.img} alt={data.name} />
      <div className={styles.name}>
        <p>{data.name}</p>
        <img
          src={require("../../../../../assets/verified.png")}
          alt="verified"
        />
      </div>
      <div className={styles.transCard}>
        <div>
          <span>Floor</span>
          <div>
            <p>{info?.floor_price ? info?.floor_price : 0}</p>
            <img
              src={require("../../../../../assets/eth.png")}
              alt="ethereum"
            />
          </div>
        </div>
        <div>
          <span>Volume</span>
          <div>
            <p>{info?.volume}k</p>
            <img
              src={require("../../../../../assets/eth.png")}
              alt="ethereum"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
